import '../scss/home.scss';

import 'fancybox';
import inViewport from 'in-viewport';

function counter() {
    $.fn.jQuerySimpleCounter = function (options) {
        let settings = $.extend({
            start: 0,
            end: 100,
            easing: 'swing',
            duration: 400,
            complete: ''
        }, options);

        const thisElement = $(this);

        $({count: settings.start}).animate({count: settings.end}, {
            duration: settings.duration,
            easing: settings.easing,
            step: function () {
                let mathCount = Math.ceil(this.count);
                thisElement.text(mathCount);
            },
            complete: settings.complete
        });
    };

    $(".counter .counter-value").each(function (i, counter) {
        inViewport(counter, function (counter) {
            if (!$(counter).hasClass("animated")) {
                $(counter).addClass("animated");
                $(counter).jQuerySimpleCounter({
                    start: 0,
                    end: $(counter).attr("data-value"),
                    duration: 2000
                });
            }
        });
    });
}

(function () {
    counter();
}());
